@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  border-radius: 6px;
  margin-block: 5px;
}

::-webkit-scrollbar-thumb {
  background: #d1d5db;
  border-radius: 6px;
}

select {
  border: 0 !important; /*Removes border*/
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  text-overflow: '';
  text-indent: 0.01px; /* Removes default arrow from firefox*/
  text-overflow: ''; /*Removes default arrow from firefox*/
  background-image: none;
}

select::-ms-expand {
  display: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 1em;
}

button.react-calendar__navigation__arrow.react-calendar__navigation__prev-button,
button.react-calendar__navigation__arrow.react-calendar__navigation__next-button {
  @apply scale-250 text-primary lg:scale-350;
}

button.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button {
  display: none;
}

button.react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
  display: none;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #d1d5db;
  background-color: rgb(249 250 251);
}

.react-calendar__month-view__days {
  @apply mt-6 rounded-xl text-sm leading-5 ring-1 ring-gray-200;
}

.react-calendar__tile.react-calendar__month-view__days__day {
  @apply p-4 ring-gray-200;
}

.react-calendar__tile.react-calendar__month-view__days__day:not(.available) {
  @apply pointer-events-none bg-gray-50 text-gray-400;
}

.react-calendar__tile.react-calendar__month-view__days__day:hover {
  @apply bg-blue-100;
}

.react-calendar__tile.react-calendar__tile--now {
  @apply bg-orange-50;
}

.react-calendar__tile--active {
  @apply flex items-center justify-center !bg-primary !text-white before:block before:bg-primary;
}

.react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from {
  @apply text-base font-semibold capitalize leading-5;
}

@media only screen and (min-width: 768px) {
  .react-calendar__month-view__days {
    @apply text-lg leading-7;
  }
}

.react-calendar__month-view__weekdays {
  @apply rounded-lg bg-blue-100 p-4 text-center text-sm font-medium no-underline lg:text-lg;
}

.react-calendar {
  font-family: Arial, Helvetica, sans-serif;
  @apply w-full bg-white leading-5 text-gray-900;
}

.react-calendar__month-view__weekdays__weekday abbr {
  @apply no-underline;
}

.selected {
  @apply block rounded-full bg-primary !text-white;
}

.active-step {
  @apply bg-primary text-blue-900;
}

.step-item {
  @apply flex items-center
  [&:not(:last-child)]:w-full
  [&:not(:last-child)]:after:inline-block
  [&:not(:last-child)]:after:h-1
  [&:not(:last-child)]:after:w-full
  [&:not(:last-child)]:after:border-4
  [&:not(:last-child)]:after:border-b
  [&:not(:last-child)]:after:content-[''];
}

.step-item-complete {
  @apply [&:not(:last-child)]:after:border-green-700;
}

.active-step-line {
  @apply text-blue-900 [&:not(:last-child)]:after:border-primary;
}

@layer components {
  .header-1 {
    @apply mb-20 text-center text-2xl font-bold md:text-3xl lg:text-4xl;
  }
}

.user-area-input {
  @apply pointer-events-none mb-5 text-lg font-normal transition-all;
}

.user-area-input-active {
  @apply pointer-events-auto my-2 block w-full rounded-full border border-gray-400 bg-white p-4 text-lg text-gray-900 transition-all focus:border-blue-500 focus:ring-blue-500 disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-400 md:text-xl;
}

.guide-button {
  @apply mx-auto w-full rounded-full px-8 py-3 text-center font-normal  sm:px-16 sm:text-lg md:justify-self-end;
}

.wrapper {
  @apply mx-auto flex w-full max-w-screen-2xl flex-col items-center px-4 py-7 md:py-8 lg:px-10;
}

.szh-accordion__item .szh-accordion__item-content {
  transition: height 0.2s cubic-bezier(0, 0, 0, 1);
}

.cs-page-loading {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-transition: all 0.4s 0.2s ease-in-out;
  transition: all 0.4s 0.2s ease-in-out;
  opacity: 1;
  z-index: 9999;
}

.cs-page-loading-inner {
  position: absolute;
  width: 100%;
  text-align: center;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
  opacity: 1;
}

.cs-page-loading-inner > p {
  display: block;
  font-family: 'Inter', sans-serif;
  font-size: 1rem;
  font-weight: normal;
}

.cs-page-spinner {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  transform: rotate(45deg);
  transform-origin: 40px 40px;
}

.cs-page-spinner > div {
  top: 32px;
  left: 32px;
  position: absolute;
  width: 32px;
  height: 32px;
  background: #dc2626;

  animation: spinner 1.2s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
}

.cs-page-spinner > div:after,
.cs-page-spinner > div:before {
  background: #dc2626;
  content: ' ';
  position: absolute;
  display: block;
  width: 32px;
  height: 32px;
}

.cs-page-spinner > div:before {
  left: -24px;
  border-radius: 50% 0 0 50%;
}

.cs-page-spinner > div:after {
  top: -24px;
  border-radius: 50% 50% 0 0;
}

@keyframes spinner {
  0% {
    transform: scale(0.95);
  }
  5% {
    transform: scale(1.1);
  }
  39% {
    transform: scale(0.85);
  }
  45% {
    transform: scale(1);
  }
  60% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(0.9);
  }
}
